@import '../../styles/variables';
@import '../../styles/mixins';


.player {
  border-radius: 7px;
  background: rgba(54, 123, 141, 0.16);
  display: flex;
  align-items: center;
  padding: 10px;
  button {
    margin: 8px 10px;
    cursor: pointer;
    padding: 0px;
    background-color: transparent;
    border: none;
    @include flex-center;
    flex-direction: column;
    width: 35px;
    height: 35px;
    font-size: 40px;
    color: $dark;
  }
  p {
    margin: 0px 20px;
  }
}
