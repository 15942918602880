@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.container {
  height: 100vh;
  width: 100%;
  background-image: url('../../assets/images/bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: auto;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 30px;
  @media only screen and (min-height: 650px) {
    overflow: hidden;
    padding-top: 0px;
    @include flex-center;
  }
  @media only screen and (min-width: $xl-screen) {
    overflow: hidden;
    @include flex-center;
  }
  .logo {
    transform: scale(1.1);
    @media only screen and (min-width: $xl-screen) {
      position: absolute;
      transform: scale(1);
      top: 20px;
      left: 30px;
    }
  }
  .content {
    @include flex-center;
    flex-direction: column;
    text-align: center;
    color: $white;
    h1 {
      padding: 0px;
      font-size: 16px;
      line-height: 20px;
      margin-top: 40px;
      font-family: "Poppins-SemiBoldItalic";
      br {
        &:not(.mobile) {
          display: none;
        }
      }
      @media only screen and (min-width: 1025px) {
        font-size: 38px;
        line-height: 42px;
        br {
          &:not(.mobile) {
            display: inline;
          }
        }
        .mobile {
          display: none;
        }
      }
    }
    p {
      font-size: 13px;
      max-width: 500px;
      padding: 0px 20px;
      @media only screen and (min-width: 1025px) {
        font-size: 20px;
        max-width: 800px;
        padding: 0px;
      }
    }
    .showroom {
      @include btn;
      margin-top: 20px;
      text-decoration: none;
      color: #023543;
      font-size: 16px;
      border-radius: 10px;
      border: 3px solid #367B8D;
      @media only screen and (min-width: 360px) {
        margin-top: 50px;
      }
      @media only screen and (min-width: $xl-screen) {
        margin-top: 30px;
        font-size: 28px;
        border-radius: 20px;
        border: 6px solid #367B8D;
      }
    }
  }
}