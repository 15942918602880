@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.container {
  height: 100%;
  width: 100%;
  header {
    position: absolute;
    top: 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    z-index: 0;
    .logo {
      margin-top: 20px;
      margin-left: 30px;
      display: none;
      @media only screen and (min-width: $xl-screen) {
        display: block;
      }
    }
    h1 {
      padding: 30px 30px 20px 30px;
      color: $dark;
      font-size: 35px;
      line-height: 35px;
      min-width: 400px;
      background-color: $white;
      text-align: center;
      border-bottom-left-radius: 25px;
      border-bottom-right-radius: 25px;
    }
    .toggle {
      width: 148px;
    }
    @media only screen and (min-width: $xl-screen) {
      z-index: 10;
    }
  }
  .img {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: rgba($dark, 1);
  }
  .loader {
    background-color: rgba($dark, 1);
    width: 100%;
    height: 100%;
    @include flex-center;
  }
}