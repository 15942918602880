@import '../../styles/variables';
@import '../../styles/mixins';

.toggle {
  @include btn;
  @include flex-center;
  border-radius: 40px;
  padding: 0px;
  width: 54px;
  height: 54px;
  margin: 5px;
  border: 2px solid $primary-color;
  img {
    width: 25px;
  }
  @media only screen and (min-width: $xl-screen) {
    margin: 15px 20px;
    width: 60px;
    height: 60px;
    border: 2.5px solid $primary-color;
    img {
      width: 30px;
    }
  }
}
.container {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  pointer-events: none;
  overflow-x: auto;
  opacity: 0;
  background-image: url('./images/bg.svg');
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 11;
  @media only screen and (min-width: $xl-screen) {
    @include flex-center;
  }

  &.active {
    pointer-events: all;
    opacity: 1;
  }
  .logo {
    position: fixed;
    top: 15px;
    left: 23px;
    img {
      width: 110px;
    }
    @media only screen and (min-width: $xl-screen) {
      display: none;
    }
  }
  .title {
    background-color: transparent;
    font-family: "Poppins-SemiBoldItalic";
    margin: 0px;
    padding: 20px 0px 0px 0px;
    min-width: none;
    color: $white;
    font-size: 35px;
    margin-bottom: 10px;
    line-height: 35px;
    display: none;
    @media only screen and (min-width: $xl-screen) {
      display: block;
    }
  }
  p {
    font-size: 20px;
    color: $white;
    margin-bottom: 30px;
    display: none;
    @media only screen and (min-width: $xl-screen) {
      display: block;
    }
  }
  .box {
    width: calc(100% - 40px);
    margin: 75px 0px 20px 0px;
    border-radius: 10px;
    @media only screen and (min-width: $xl-screen) {
      margin: 0px;
      height: 65vh;
      width: 85%;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23fff' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    }
    .top {
      display: flex;
      flex-direction: column;
      @media only screen and (min-width: $xl-screen) {
        flex-direction: row;
        height: 70%;
        width: 100%;
        padding: 10px 10px 5px 10px;
      }
      .col {
        @media only screen and (min-width: $xl-screen) {
          padding: 10px 10px 5px 10px;
          width: calc(87% / 5 );
          height: 100%;
          &:nth-child(3) {
            width: 13%;
          }
          &.last {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .item {
              height: calc(70% - 10px);
              width: 100%;
              &:first-child {
                height: calc(30% - 10px);
              }
            }
          }
        }
      }
    }
    .bottom {
      @media only screen and (min-width: $xl-screen) {
        display: flex;
        height: 30%;
        width: 100%;
        padding: 5px 10px 10px 10px;
        .col {
          padding: 5px 10px 10px 10px;
          width: calc(80% / 2 );
          height: 100%;
          &:nth-child(2) {
            width: 20%;
          }
        }
      }
    }
  }
}

.link {
  border: 1px solid $white;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  padding: 18px;
  margin: 6px 0px;
  @include flex-center;
  text-decoration: none;
  color: $white;
  @include transition;
  position: relative;
  @media only screen and (min-width: $xl-screen) {
    padding: 10px;
    border-radius: 10px;
    margin: 0px;
    display: flex;
    flex-direction: column;
  }
  h2 {
    text-decoration: none;
    font-family: "Poppins-SemiBold";
    text-align: center;
    margin: 0px;
    font-size: 16px;
    line-height: 16px;
    @media only screen and (min-width: $xl-screen) {
      font-size: 18px;
      line-height: 18px;
      font-family: "Poppins-SemiBoldItalic";
    }
  }
  &:hover {
    background-color: rgba($white, 0.2);
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.2;
  }
  .new {
    background-color: $white;
    position: absolute;
    right: 10px;
    width: 30px;
    height: 30px;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #E42313;
    font-size: 20px;
    p {
      display: none;
      margin: 0px;
      color: $dark;
      @include semiBold;
      font-size: 13px;
      line-height: 12px;
      margin: 1px 5px 0px 5px;
    }
    @media only screen and (min-width: $xl-screen) {
      position: relative;
      width: auto;
      padding: 0px 6px 0px 8px;
      margin-top: 10px;
      font-size: 17px;
      right: 0px;
      p {
        display: block;
      }
    }
  }
  &.active {
    background-color: $white;
    color: $dark;
    .new {
      border: 1px solid $dark;
    }
  }
  .pin {
    position: absolute;
    width: 100%;
    bottom: 0px;
    left: 8px;
    p {
      color: $dark;
      margin: 5px 0px 0px 0px ;
      font-size: 14px;
      display: none;
    }
    @media only screen and (min-width: $xl-screen) {
      bottom: 10px;
      @include flex-center;
      flex-direction: column;
      left: 0px;
      p {
        display: block;
      }
    }
  }
}