@import '../../../../styles/variables';
@import "../../../../styles/_mixins.scss";

.resetpwd-form{
  width: 100%;
  .form {
    // margin-bottom: $margin-lg;
    .container-field{
      margin: 0 0 15px 0;
    }
    .message-error{
      color : $warn;
      font-size: 12px;
    }
    .message-succeed{
      @include regular;
      color : $success;
      font-size: $regular;
      text-align: center;
    }

    .submit {
      @include flex-center;
      flex-direction: column;
      margin-top: 10px;
      button {
        @include btn;
      }
    }

    button.btnSucceed{
      margin-top: $margin-lg;
      width: 100%;
      white-space: nowrap;
      margin-bottom: $margin-sm;
    }
 
  }

}
