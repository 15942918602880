@import "../form-mixin";

.container {
  ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    li {
      display: flex;
      margin-bottom: 10px;
      position: relative;
      .icon {
        @include flex-center;
        margin-right: 8px;
        .index {
          width: 30px;
          height: 30px;
          border-radius: 15px;
          background-color: $primary-color;
          @include flex-center;
          color: $white;
        }
      }
      .input {
        @include input;
      }
      button {
        position: absolute;
        right: 0px;
        top: 0px;
        height: 41px;
        width: 41px;
        @include flex-center;
        background-color: transparent;
        border: none;
        font-size: 18px;
        color: rgba($dark, 0.3);
        cursor: pointer;
        &:hover {
          color: $warn;
        }
      }
    }
  }
  .add {
    background-color: transparent;
    border: none;
    @include semiBold;
    color: $dark;
    text-decoration: underline;
  }
}