@import '../../../../styles/variables';
@import "../../../../styles/_mixins.scss";

.emailresetpwd-form{
  width: 100%;

  .form{
    margin-bottom: $margin-md;
    .container-field{
      margin: 0 0 15px 0;
    }
    .message-error{
      color : $warn;
      font-size: $regular;
    }
    .message-succeed{
      @include regular;
      color : $success;
      font-size: $regular;
    }
  }

  .containerSubmit {
    @include flex-center;
    flex-direction: column;
    .btn {
      @include btn;
    }
    a {
      display: block;
      position: relative;
      color: $text-color;
      margin-top: $margin-sm;
      text-align: center;
    }
  }

}


