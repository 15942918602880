@import '../../styles/variables';
@import '../../styles/mixins';

.tab {
  .header {
    max-width: 700px;
    h2 {
      font-size: 14px;
      color:#355D69;
    }
    .infos {
      display: flex;
      margin-bottom: 20px;
      width: 100%;
      justify-content: space-between;
      .input {
        width: calc(50% - 10px);
      }
    }
  }
}