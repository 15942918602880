@mixin regular {
  font-family: "Poppins-Regular", sans-serif;
}

@mixin semiBold {
  font-family: "Poppins-SemiBold", sans-serif;
}

@mixin transition {
  transition: all 0.2s ease-in-out;
}

@mixin customBorder {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23c1bfbf' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba($black, 0.5);
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $black;
  }
}

@mixin label {
  @include regular;
  font-size: 13px;
  line-height: 13px; 
  color: $dark;
  white-space: nowrap;
  display: block;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center
}

@mixin btn-reset {
  @include semiBold;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

@mixin btn {
  @include semiBold;
  @include transition;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $button;
  background-color: $white;
  padding: 11px 20px;
  color: $dark;
  border: solid 1px $white;
  border-radius: 10px;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  box-shadow: 0px 0px 20px rgba($black, 0.2);
  @include remove-tap-highlight();
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &.with-icon {
    svg {
      margin-right: 8px;
      font-size: 20px;
    }
  }
  &.icon {
    padding: 0px;
    width: 45px;
    min-width: 45px;
    height: 45px;
    svg {
      font-size: 20px;
    }
  }
  &.primary {
    background-color: $primary-color;
    border: solid 1px $primary-color;
    color: $white;
  }
}

@mixin container {
  margin: auto;
  width: 100%;
  max-width: 1024px;
  padding: $margin-md;
  margin: 0 auto;
}

@mixin page {
  position: relative;
  margin: auto;
  flex: 1;
  padding: $margin-md;
  margin: 0 auto;
  overflow: auto;
  max-height: 100%;
}

@mixin initials {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: $margin-xs;
  border-radius: 50%;
  background-color: $primary-color;
  border: 2px solid #f0f0f0;
  background-position: center;
  background-size: cover;

  > span {
    position: relative;
    font-family: "DMSans";
    font-size: $medium;
    color: $white;
  }
}

@mixin containerField {
  margin-bottom: 20px;
}

@mixin remove-highlight() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin remove-tap-highlight() {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

@mixin content {
  height: calc(100vh - 125px);
  width: 100%;
  overflow-y: auto;
  @include scrollbar;
  @media only screen and (min-width: $xl-screen) {
    background-color: #F6F7F8;
    height: calc(100vh - 70px);
    margin-top: 70px;
  }
}

@mixin authTemplate {
  height: 100vh;
  width: 100%;
  background-image: url('../../assets/images/bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  @include flex-center;
  .logo {
    position: absolute;
    top: 20px;
    left: 30px;
  }
  .content {
    position: absolute;
    right: 0px;
    width: 45%;
    height: 100%;
    z-index: 1;
    overflow: auto;
    background-color: $bg-color;
    @include flex-center;
    .form {
      width: 100%;
      max-width: 350px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      h2 {
        width: 100%;
        text-align: left;
        color: $dark;
      }
      h3 {
        font-size: 16px;
      }
      button {
        @include btn;
        margin-top: 10px;
        margin-bottom: 20px;
      }
    }
  }
}

@mixin btn-edit {
  border: none;
  background-color: transparent;
  @include flex-center();
  cursor: pointer;
  span {
    display: none;
    @include semiBold();
    margin-left: 10px;
    color: $primary-color;
    text-transform: uppercase;
  }
  .icon {
    background-color: #A2B1CE;
    width: 41px;
    height: 41px;
    border-radius: 22px;
    border: none;
    @include flex-center();
  }
  @media only screen and (min-width: $xl-screen) {
    .icon {
      background-color: #A2B1CE;
      width: 32px;
      height: 32px;
      border-radius: 22px;
      border: none;
      @include flex-center();
    }
    span {
      display: block;
    }
  }
}
@mixin dotLoader() {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-color: $primary-color;
  border-radius: 7px;
  animation-iteration-count: infinite;
  animation-duration: 1s;
  animation-name: rotate;
  margin-right: $margin-sm;
}
@keyframes rotate {
  0% {
    transform: rotateX(0) rotateY(0);
  }
  50% {
    transform: rotateX(-180deg) rotateY(0);
  }
  100% {
    transform: rotateX(-180deg) rotateY(-180deg);
  }
}
