@import '../../styles/variables';
@import '../../styles/mixins';


.field {
  @include transition;
  width: 100%;
  position: relative;
  display: flex;
  align-items: flex-start;
  &:hover {
    .actions {
      opacity: 1;
      pointer-events: all;
    }
  }
  .actions {
    opacity: 0;
    @include transition;
    pointer-events: none;
    display: flex;
    button {
      @include btn;
      margin-left: 10px;
      &.icon {
        width: 42px;
        min-width: 42px;
        height: 42px;
      }
      &:nth-child(1) {
        color: $warn;
      }
    }
  }
  .title {
    max-width: 700px;
    width: 100%;
    margin-bottom: 20px;
  }
  .sound {
    max-width: 700px;
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.browse {
      .mp3 {
        display: flex;
        align-items: center;
        width: 117px;
      }
      .input {
        width: calc(100% - 127px);
      }
    }
    .mp3 {
      display: flex;
      align-items: center;
      width: calc(50% - 10px);
      @include transition;
      button {
        @include btn;
        &.icon {
          width: 35px;
          min-width: 35px;
          height: 35px;
          margin: 0px 10px;
        }
        color: $warn;
      }
    }
    .input {
      width: calc(50% - 10px);
      @include transition;
      input {
        min-width: 100%;
      }
    }
  }
  .media {
    max-width: 700px;
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    .picture {
      width: calc(50% - 10px);
      height: 187px;
      background-color: $inputBg;
      border: 1px solid rgba(#023543, 0.2);
      color: $inputColor;
      border-radius: 5px;
      overflow: hidden;
      position: relative;
      img, video {
        width: 100%;
        border-radius: 10px;
        overflow: hidden;
        &.image {
          height: 100%;
          object-fit: cover;
        }
      }
      button {
        @include btn;
        position: absolute;
        bottom: 10px;
        right: 10px;
        &.icon {
          width: 35px;
          min-width: 35px;
          height: 35px;
        }
        color: $warn;
      }
    }
    .infos {
      width: calc(50% - 10px);
      .input {
        margin-bottom: 15px;
        width: 100%;
      }
    }
  }
  .text {
    max-width: 700px;
    width: 100%;
    margin-bottom: 20px;
  }
  .separator {
    max-width: calc(100% - 104px);
    width: 100%;
    margin-bottom: 20px;
    height: 42px;
    display: flex;
    align-items: center;
    .bar {
      width: 100%;
      height: 1px;
      border-bottom: $border;
    }
  }
  .list {
    max-width: 700px;
    width: 100%;
    margin-bottom: 20px;
  }
}
