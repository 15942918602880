@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.container-input{

  label {
    @include label;
  }
  
  &.inline {
    @include inline;
  }

  &:not(.inline){
    label+.input {
      margin-top: 10px;
    }
  }
  
  input{
    @include input();
  }
  &.primary {
    @include primary;
  }
  &.title {
    @include primary;
    .input {
      @include semiBold;
      color: $dark;
      font-size: 14px;
    }
  }
  &.login {
    @include login;
  }
  
}
