$bg-color: #f3f7f8;
$white: #FFFFFF;
$primary-color: #367B8D;
$primary-light: #DDEBEF;
$secondary-color: #A2B1CE;
$success: #5BC284;
$warn: #FA5C4F;

$stat-great: #74BE73;
$stat-warn: #FFA945;
$stat-critical: #E76D6D;

$white: #FFFFFF;

$light-grey: #CDD0D5;
$grey: #9BA0AB;

$black: #222429;
$dark: #023543;
$text-color: #818283;
$text-light: #9BA0AB;

$inputBg: #FAFCFB;
$inputColor: $black;

$xs-screen: 500px;
$sm-screen: 576px;
$md-screen: 768px;
$lg-screen: 992px;
$xl-screen: 1024px;
$xxl-screen: 1400px;

$margin-xl : 50px;
$margin-lg : 30px;
$margin-md : 20px;
$margin-sm : 15px;
$margin-xs : 10px;


$h1: 36px;
$h2: 25px; 
$h3: 22px;
$medium: 18px;
$button: 14px;
$regular: 13px;
$radius: 7px;

$border: 1px solid #A4B7BB;


@font-face {
	font-family: "Montserrat-Bold";
	src: url("../assets/fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "Poppins-SemiBold";
	src: url("../assets/fonts/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
	font-family: "Poppins-Regular";
	src: url("../assets/fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Poppins-SemiBoldItalic";
	src: url("../assets/fonts/Poppins-SemiBoldItalic.ttf") format("truetype");
}
