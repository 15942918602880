@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.field {

  label {
    @include label;
    + div {
      margin-top: 10px;
    }
  }

  .texteditor {
    padding: 0;
    // height: 170px;
    // max-height: 170px;
    border: 1px solid rgba(#023543, 0.2);
    border-radius: $radius;
    color: $white;
    background-color: $inputBg;
    a {
      display: inline;
    }
    &.discret {
      background-color: transparent;
    }
  }

  .count-characters {
    text-align: right;
    font-size: 11px;
    margin: 0;
    padding: $margin-sm;
    color: $dark;
    span {
      color: $dark;
    }
  }
}