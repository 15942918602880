@import '../../styles/variables';
@import '../../styles/mixins';

.container {
  width: 100%;
  max-width: 700px;
  margin-bottom: 20px;
  .toggle {
    @include btn;
    @include flex-center;
    font-size: 20px;
    width: 100%;
    border-radius: $radius;
    padding: 12px 10px;
    margin-bottom: 20px;
    color: $primary-color;
    background-color: transparent;
    box-shadow: none;
    @include customBorder;
    &:hover {
      color: $primary-color;
      background-color: rgba($primary-color, 0.2);
    }
  }
  .dropdown {
    position: fixed;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    z-index: 10;
    background-color: $white;
    border: $border;
    width: 200px;
    height: 315px;
    border-radius: 10px;
    button {
      background-color: transparent;
      border: none;
      width: 100%;
      padding: 12px 15px;
      cursor: pointer;
      display: flex;
      align-items: center;
      position: relative;
      @include regular;
      p {
        margin: 0px 0px 0px 16px;
      }
      &:hover {
        background-color: rgba($primary-color, 0.2);
        svg {
          color: $primary-color;
        }
      }
    }
  }
}