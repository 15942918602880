@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.layout{
  display: flex;
  height: 100vh;
  width: 100%;
  .nav {
    width: 360px;
    height: 100vh;
    border-right: 1px solid #A4B7BB;
  }
  .container {
    width: calc(100% - 360px);
    background-color: $bg-color;
    height: 100vh;
    overflow-y: auto;
    @include scrollbar;
    &::-webkit-scrollbar-thumb {
      background: rgba($primary-color, 0.5);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: $primary-color;
    }
  }
}