@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.container-input{

  label {
    @include label;
  }
  
  &.inline {
    @include inline;
  }

  &:not(.inline){
    label+.input {
      margin-top: 10px;
    }
  }
  
  textarea {
    @include input();
    max-width: 100%;
    min-width: 100%;
    min-height: 130px;
  }
  &.primary {
    @include primary;
  }
  &.title {
    @include primary;
    .input {
      @include semiBold;
      padding: 8px 12px;
      color: $dark;
      min-height: 40px;
      max-height: 120px;
      font-size: 14px;
    }
  }
  &.login {
    @include login;
  }
  
}
