@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.container {
  height: 100vh;
  width: 100vw;
  background-image: url('../../assets/images/bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  @include flex-center;
  position: relative;
  .logo {
    position: absolute;
    top: 15px;
    left: 23px;
    img {
      width: 110px;
    }
    @media only screen and (min-width: $xl-screen) {
      top: 20px;
      left: 30px;
      img {
        width: auto;
      }
    }
  }
  .content {
    @include flex-center;
    flex-direction: column;
    text-align: center;
    color: $white;
    width: 100%;
    h1 {
      font-size: 36px;
      line-height: 36px;
      margin-bottom: 20px;
      font-family: "Poppins-SemiBoldItalic";
      @media only screen and (min-width: $xl-screen) {
        font-size: 96px;
        line-height: 96px;
        margin-bottom: 40px;
      }
    }
    p {
      font-size: 16px;
      max-width: 80%;
      @media only screen and (min-width: $xl-screen) {
        font-size: 20px;
        max-width: 800px;
      }
    }
    .showroom {
      @include btn;
      margin-top: 20px;
      text-decoration: none;
      color: #023543;
      font-size: 16px;
      border-radius: 10px;
      border: 3px solid #367B8D;
    }
  }
}