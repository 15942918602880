@import '../../styles/variables';
@import '../../styles/mixins';

.container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  pointer-events: none;
  opacity: 0;
  top: 0px;
  z-index: 10;
  background-image: url('./images/bg.svg');
  height: 100vh;
  width: 100%;
  overflow: auto;
  @include transition;
  @media only screen and (min-width: $xl-screen) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    overflow: hidden;
  }
  &.active {
    pointer-events: all;
    opacity: 1;
  }
  .content {
    width: 100%;
    margin-top: 310px;
    background-color: $white;
    @media only screen and (min-width: $xl-screen) {
      margin-top: 0px;
      width: 70%;
      height: 100%;
    }
    header {
      position: fixed;
      display: flex;
      align-items: center;
      padding: 8px 20px;
      z-index: 200;
      background-image: url('./images/bg.svg');
      outline: 2px dotted #FFFFFF;
      .logo {
        margin-top: 8px;
        img {
          width: 130px;
        }
      }
      .back {
        display: block;
        right: 20px;
        top: 10px;
        img {
          transform: translateY(2px);
        }
      }
      @media only screen and (min-width: $xl-screen) {
        display: none;
      }
    }
    .back {
      @include btn;
      position: absolute;
      right: 20px;
      top: 20px;
      border-radius: 30px;
      border: 2px solid $primary-color;
      display: none;
      align-items: center;
      img {
        margin-right: 8px;
      }
      @media only screen and (min-width: $xl-screen) {
        display: flex;
      }
    }
    .cover {
      position: absolute;
      left: 20px;
      top: 100px;
      width: calc(100% - 40px);
      border-radius: 23px;
      @include transition;
      &.video {
        height: 260px;
        background-color: $primary-color;
        video {
          object-fit: cover;
        }
        .play {
          cursor: pointer;
          pointer-events: all;
          display: block;
        }
        &.open {
          height: auto;
          left: 5%;
          background-color: transparent;
          width: auto;
          border-radius: 10px;
          width: 90%;
          @media only screen and (min-width: $xl-screen) {
            width: 70%;
            left: 15%;
          }
          video {
            object-fit: cover;
            z-index: 210;
            max-height: 80vh;
            box-shadow: 1px 3px 16px 4px rgba(0,0,0,0.32);
          }
        }
      }
      &.open {
        position: fixed;
        z-index: 210;
        top: 45px;
        width: calc(100% - 100px);
        z-index: 300;
        height: 82vh;
        video {
          z-index: 220;
        }
        @media only screen and (min-width: $xl-screen) {
          top: 15%;
        }
        .play {
          display: none;
        }
        .close {
          @include flex-center;
          img {
            cursor: pointer;
          }
        }
        img {
          cursor: auto;
          height: 100%;
        }
        video {
          opacity: 1;
          pointer-events: all;
        }
      }
      .close {
        display: none;
        position: absolute;
        background-color: $primary-color;
        width: 30px;
        height: 30px;
        right: -10px;
        top: -10px;
        z-index: 100;
        border: none;
        border-radius: 50px;
        cursor: pointer;
        @include transition;
        &:hover {
          transform: scale(1.1);
        }
        img {
          width: 14px;
          object-fit: contain;
        }
        @media only screen and (min-width: $xl-screen) {
          width: 50px;
          height: 50px;
          right: -20px;
          top: -20px;
          img {
            width: 22px;
          }
        }
      }
      .play {
        display: none;
        position: absolute;
        width: 60px;
        object-fit: contain;
        overflow: visible;
        left: calc(50% - 15px);
        height: 100%;
        top: 0px;
      }
      img {
        height: 260px;
        width: 100%;
        pointer-events: none;
        z-index: 10;
        border-radius: 23px;
        cursor: pointer;
        object-fit: cover;
        overflow: hidden;
        @media only screen and (min-width: $xl-screen) {
          height: auto;
        }
      }
      video {
        pointer-events: none;
        opacity: 0.5;
        width: 100%;
        max-height: 100%;
        height: 100%;
        z-index: 100;
        border-radius: 23px;
        object-fit: cover;
        overflow: hidden;
      }
      @media only screen and (min-width: $md-screen) {
        left: 50px;
        width: calc(100% - 100px);
      }
      @media only screen and (min-width: $xl-screen) {
        width: 32vw;
        &.video {
          height: 82vh;
        }
      }
    }
    .tabs {
      position: relative;
      @media only screen and (min-width: $xl-screen) {
        overflow: hidden;
        margin: 120px 0px 0px 14%;
        height: 100%;
      }
      h1 {
        color: $dark;
        font-size: 34px;
        line-height: 34px;
        font-family: "Poppins-SemiBoldItalic";
        margin: 60px 20px 20px 20px;
        @media only screen and (min-width: $md-screen) {
          margin: 60px 50px 20px 50px;
        }
        @media only screen and (min-width: $xl-screen) {
          margin: 0px 0px 20px 0px;
        }
      }
      .toggles {
        width: calc(100% - 40px);
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin: 20px;
        @media only screen and (min-width: $md-screen) {
          width: calc(100% - 100px);
          margin: 20px 50px;
        }
        @media only screen and (min-width: $xl-screen) {
          margin: 0px 0px 20px 0px;
          width: 90%;
          align-items: flex-end;
        }
        button {
          width: calc(50% - 5px);
          background: transparent;
          border: none;
          text-align: left;
          color: $dark;
          opacity: 0.4;
          @include transition;
          cursor: pointer;
          padding: 0px;
          flex: 1;
          margin-left: 5px;
          &:first-child {
            margin-right: 5px;
            margin-left: 5px;
          }
          @media only screen and (min-width: $xl-screen) {
            border-bottom: 1.5px solid $primary-color;
            width: calc(50%);
            padding: 1px 6px;
            margin-right: 0px;
            margin-left: 0px;
            &:first-child {
              margin-right: 0px;
              margin-left: 0px;
            }
            flex: none;
          }
          div {
            box-shadow: 0px 0px 26.437931060791016px rgba(2, 53, 67, 0.15);
            padding: 10px 15px;
            border: 1px solid $primary-color;
            border-radius: 10px;
            margin-bottom: 10px;
            @include transition;
            @media only screen and (min-width: $xl-screen) {
              padding: 10px 15px;
            }
          }
          &.active {
            opacity: 1;
            div {
              color: $white;
              background-color:$primary-color;
            }
          }
          &:hover {
            opacity: 1;
            div {
              color: $white;
              background-color:$primary-color;
            }
            @media only screen and (min-width: $xl-screen) {
              border-bottom: 1.5px solid rgba($primary-color, 0.6);;
              div {
                background-color: rgba($primary-color, 0.2);
                color: $primary-color;
              }
            }
          }
          p {
            text-transform: uppercase;
            @include semiBold;
            margin-bottom: 10px;
          }
          h2 {
            margin-bottom: 0px;
            font-size: 18px;
            line-height: 18px;
            margin-bottom: 10px;
            word-wrap: break-word;
            @media only screen and (min-width: $xl-screen) {
              font-size: 25px;
              line-height: 25px;
            }
          }
        }
      }
      .tab {
        position: absolute;
        width: 100%;
        min-height: 50vh;
        display: none;
        pointer-events: none;
        @include transition;
        @include scrollbar;
        background-color: $white;
        padding: 0px 20px;
        @media only screen and (min-width: $md-screen) {
          padding: 0px 50px;
        }
        @media only screen and (min-width: $xl-screen) {
          overflow: auto;
          opacity: 0;
          display: none;
          padding: 0px;
        }
        &.active {
          opacity: 1;
          pointer-events: all;
          display: block;
        }
      }
    }
  }
}

.field {
  width: 100%;
  color: $dark;
  pointer-events: all;
  @media only screen and (min-width: $xl-screen) {
    width: 90%;
  }
  h3 {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 8px;
    padding-right: 20px;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
  .bar {
    width: 100%;
    height: 1px;
    border-bottom: 1.5px solid rgba($primary-color, 0.2);
    margin-bottom: 28px;
  }
  .text {
    margin-bottom: 20px;
    p {
      margin: 0px;
    }
    a {
      color: $primary-color;
    }
  }
  ul {
    list-style: none;
    margin-bottom: 30px;
    padding: 0px;
    li {
      display: flex;
      align-items: flex-start;
      margin-bottom: 8px;
      .index {
        width: 26px;
        height: 26px;
        min-width: 26px;
        border-radius: 15px;
        background-color: $primary-color;
        @include flex-center;
        color: $white;
        line-height: 14px;
      }
      p {
        margin: 0px 0px 0px 10px;
      }
    }
  }
  .sound {
    margin-bottom: 20px;
  }
  .media {
    margin-bottom: 20px;
    cursor: pointer;
    img {
      width: 40%;
      border-radius: 10px;
      object-fit: cover;
      overflow: hidden;
    }
    video {
      width: 100%;
      border-radius: 10px;
      object-fit: cover;
      pointer-events: none;
      overflow: hidden;
    }
  }
}