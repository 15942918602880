@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.nav {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .logo {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 20px 0px;
  }
  .links {
    width: 100%;
    a {
      @include transition;
      position: relative;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: rgba($dark, 0.8);
      background-color: transparent;
      padding: 15px 20px;
      .bar {
        position: absolute;
        @include transition;
        left: 0px;
        height: 100%;
        width: 10px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        background-color: $primary-color;
        transform: translateX(-10px);
      }
      .icon {
        @include flex-center;
        width: 25px;
        height: 25px;
        margin-right: 5px;
      }
      p {
        margin: 0px;
        font-size: 14px;
        @include semiBold;
      }
      &:hover {
        background-color: rgba($primary-color, 0.05);
      }
      &.active {
        background-color: rgba($primary-color, 0.1);
        .bar {
          transform: translateX(0px);
        }
      }
    }
  }
  .export {
    @include btn;
    margin-bottom: 20px;
    background-color: $primary-color;
    border: 1px solid $primary-color;
    color: $white;
  }
  .logout {
    width: calc(100%);
    border-top: 1px solid #A4B7BB;
    button {
      margin: 20px 0px;
      width: 100%;
      @include btn-reset;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $primary-color;
      &:hover {
        text-decoration: underline;
      }
      svg {
        font-size: 20px;
        margin-right: 10px;
      }
      p {
        margin: 0px;
        font-size: 14px;
        @include semiBold;
      }
    }
  }
}